import React from "react";
import PropTypes from "prop-types";
import BlockContent from "@sanity/block-content-to-react";

const defaultSerializers = {};

const SimpleBlockContent = ({ blocks, serializers = {}, ...props }) => (
  <BlockContent
    blocks={blocks}
    serializers={{ ...defaultSerializers, ...serializers }}
    {...props}
  />
);

SimpleBlockContent.propTypes = {
  blocks: PropTypes.array.isRequired,
  serializers: PropTypes.object,
};

export default SimpleBlockContent;
