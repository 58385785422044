import React from 'react'
import PropTypes from "prop-types";
import styled from 'styled-components';
import { format, parseISO, addHours } from 'date-fns';
import { nb } from 'date-fns/locale';

const CalendarDay = styled.time`
  display: flex;
  margin: 0 0 ${({ theme }) => theme.margins.extraSmall} 0;

  .date {
    width: 80px;
    min-height: 80px;
    flex: 0 0 auto;
    justify-content: center;
    align-items: center;
    display: flex;
    background: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.background};
    text-transform: uppercase;
    border-top-left-radius: ${({ theme }) => theme.radius};
    border-bottom-left-radius: ${({ theme }) => theme.radius};
  }

  .events {
    flex: 1 1 auto;
    list-style: none;
    padding: 0;
    margin: 0;
    background: ${({ theme }) => theme.colors.background};
    border-top-right-radius: ${({ theme }) => theme.radius};
    border-bottom-right-radius: ${({ theme }) => theme.radius};
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

const CalendarEvent = styled.li`
  display: flex;
  padding: ${({ theme }) => theme.margins.small};
  font-size: ${({ theme }) => theme.fontSizes.small};
  border-bottom: 1px dashed ${({ theme }) => theme.colors.border};
  font-weight: 300;

  &:last-child {
    border-bottom: none;
  }

  .time {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    width: 55px;
    font-size: ${({ theme }) => theme.fontSizes.extraSmall};
    font-variant-numeric: lining-nums;
    justify-content: center;
  }

  .details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  h4 {
    font-weight: 300;
    margin: 0;
    padding: 0;
  }

  .location {
    font-size: ${({ theme }) => theme.fontSizes.extraSmall};
  }
`

const Agenda = ({ days }) => {
    return <>{days.map(day => {
        var d = parseISO(day.date);
        var k = format(d, "yyyy-MM-dd", { locale: nb });
  
        return <CalendarDay key={k} dateTime={k}>
            <div className="date">
                {format(d, "eee", { locale: nb })}<br/>
                {format(d, "dd.MM", { locale: nb })}
            </div>
            <ul className="events">
                {day.events.map((event, i) => {
                    return <CalendarEvent key={i}>
                        <div className="time">
                            {event.isFullDay ? 
                            "Hele dagen" : 
                            (
                                <>
                                {format(parseISO(event.from), "HH:mm", { locale: nb })}<br/>
                                {event.to ? 
                                    format(parseISO(event.to), "HH:mm", { locale: nb }) :
                                    format(addHours(parseISO(event.from), 2), "HH:mm", { locale: nb }) 
                                }
                                </>
                            )}
                        </div>
                        <div className="details">
                            <h4>{event.title}</h4>
                        </div>
                    </CalendarEvent>
                })}
            </ul>
        </CalendarDay>
      })}</>
}

Agenda.propTypes = {
    days: PropTypes.arrayOf(PropTypes.shape({
        date: PropTypes.string,
        events: PropTypes.arrayOf(PropTypes.shape({
            isFullDay: PropTypes.bool,
            from: PropTypes.string,
            to: PropTypes.string,
            title: PropTypes.string
        }))
      }))
}

export default Agenda;