import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { format, parseISO } from 'date-fns';
import { nb } from 'date-fns/locale';
import SanityFrontendClient from '../../lib/SanityFrontendClient';
import SimpleBlockContent from '../common/SimpleBlockContent';
import ResponsiveImage from '../common/ResponsiveImage';
import { motion } from 'framer-motion';
import { fadeInUp } from '../../lib/MotionHelper';

const ArticleGridWrapper = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: ${({ theme }) => theme.margins.medium};

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.small.maxWidth}) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const MainArticle = styled(motion.article)`
  background: ${({ theme }) => theme.colors.background};
  border-radius: ${({ theme }) => theme.radius};
  overflow: hidden;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.small.maxWidth}) {
    grid-column-start: 1;
    grid-column-end:   3;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  h2 {
    font-size: ${({ theme }) => theme.fontSizes.extraLarge};
  }

  .picture {
    display: block;
    height: 200px;

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.small.maxWidth}) {
        height: 393px;
    }

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium.maxWidth}) {
        height: 341px;
    }

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large.maxWidth}) {
        height: 412px;
    }
  }

  .ingress {
    font-size: ${({ theme }) => theme.fontSizes.medium};
  }
`

const SecondaryArticle = styled(motion.article)`
  background: ${({ theme }) => theme.colors.background};
  border-radius: ${({ theme }) => theme.radius};
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .picture {
    display: block;
    height: 200px;

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.small.maxWidth}) {
        height: 191px;
    }

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium.maxWidth}) {
        height: 164px;
    }

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large.maxWidth}) {
        height: 200px;
    }
  }

  h3 {
    font-size: ${({ theme }) => theme.fontSizes.large};
  }

  .ingress {
    font-size: ${({ theme }) => theme.fontSizes.small};
  }
`

const ArticleIngress = styled.div`
  padding: ${({ theme }) => theme.margins.small} ${({ theme }) => theme.margins.medium} ${({ theme }) => theme.margins.medium} ${({ theme }) => theme.margins.medium};

  header {
    display: flex;
    align-items: baseline;
    flex-direction: column;

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large.maxWidth}) {
      flex-direction: row;
    }
  }

  time {
    font-weight: 300;
    font-size: ${({ theme }) => theme.fontSizes.extraSmall};
    color: ${({ theme }) => theme.colors.subtitle};

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium.maxWidth}) {
      font-size: ${({ theme }) => theme.fontSizes.small};
    }

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large.maxWidth}) {
      min-width: 100px;
      padding-left: ${({ theme }) => theme.margins.medium};
    }
  }

  h2,h3 {
    flex: 1 1 auto;
    margin: 0;
    padding: 0;
    font-weight: 300;
    
    a {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.text};
    }
  }

  .ingress {
    font-weight: 200;
    font-size: ${({ theme }) => theme.fontSizes.small};

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium.maxWidth}) {
      font-size: ${({ theme }) => theme.fontSizes.small};
    }

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
`

const ArticleGrid = ({ articles, preview }) => {
  var sanityFrontendClient = new SanityFrontendClient();

  return <ArticleGridWrapper variants={{
    animate: { transition: { staggerChildren: 0.1 } }
  }}>
    {articles.map((article, i) => {
      var link = `/article/${article.slug}`;

      if (i === 0) {
        return <MainArticle key={article.slug} variants={fadeInUp}>
            <Link href="/article/[slug]" as={link}>
              <a className="picture">
                { article.thumbnail 
                  ? <ResponsiveImage urlBuilder={sanityFrontendClient.getImageUrl(article.thumbnail)} alt={article.thumbnail.alt} />
                  : <img src="https://via.placeholder.com/640x360.jpg?text=Mangler bilde" alt="Mangler bilde"/>
                }
              </a>
            </Link>
            <ArticleIngress>
              <header>
                  <h2>
                    <Link href="/article/[slug]" as={link}>
                      <a>{article.title}</a>
                    </Link>
                  </h2>
                  <time dateTime={article.date}>{format(parseISO(article.date, "yyyy-MM-dd", new Date()), "PP", { locale: nb })}</time>
              </header>
              <div className="ingress">
                {article.preamble && <SimpleBlockContent blocks={article.preamble}></SimpleBlockContent>}
                <Link href="/article/[slug]" as={link}>
                    <a>Les mer</a>
                </Link>
              </div>
            </ArticleIngress>
        </MainArticle>
      }
      else {
        return <SecondaryArticle key={article.slug} variants={fadeInUp}>
            <Link href="/article/[slug]" as={link}>
              <a className="picture">
                { article.thumbnail 
                  ? <ResponsiveImage urlBuilder={sanityFrontendClient.getImageUrl(article.thumbnail)} alt={article.thumbnail.alt} />
                  : <img src="https://via.placeholder.com/320x180.jpg?text=Mangler bilde" alt="Mangler bilde"/>
                }
              </a>
            </Link>
            <ArticleIngress>
              <header>
                  <h3>
                    <Link href="/article/[slug]" as={link}>
                      <a>{article.title}</a>
                    </Link>
                  </h3>
                  <time dateTime={article.date}>{format(parseISO(article.date, "yyyy-MM-dd", new Date()), "PP", { locale: nb })}</time>
              </header>
              <div className="ingress">
                {article.preamble && <SimpleBlockContent blocks={article.preamble}></SimpleBlockContent>}
                <Link href="/article/[slug]" as={link}>
                    <a>Les mer</a>
                </Link>
              </div>
            </ArticleIngress>
        </SecondaryArticle>
      }
    })}
  </ArticleGridWrapper>
}

export default ArticleGrid;