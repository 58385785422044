import React, { useState, useEffect } from "react";
import { motion } from "framer-motion"
import { fadeOutState } from '../../lib/MotionHelper';
import PropTypes from "prop-types";
import styled from 'styled-components';

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
`

const Image = styled(motion.img)`
    object-fit: cover;
    height: 100%;
    width: 100%;
    background-color: #1a1a1a;
`

const Placeholder = styled(motion.div)`
    position: absolute;
    top: 0;
    left: 0;
    background-color: #1a1a1a;
    height: 100%;
    width: 100%;
`

const ResponsiveImage = ({ urlBuilder, sizes, primarySize, aspectRatio, alt, types, preview = false }) => {
    const wrapperRef = React.useRef(null);
    const [isInitialized, setIsInitialized] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [height, setHeight] = useState(-1);
    const [width, setWidth] = useState(primarySize);

    useEffect(() => {
        if (wrapperRef.current) {
            setHeight(wrapperRef.current.clientHeight);
            setWidth(wrapperRef.current.clientWidth);

            setIsInitialized(true);
        }

        window.addEventListener('resize', () => {
            if (wrapperRef.current) {
                setHeight(wrapperRef.current.clientHeight);
                setWidth(wrapperRef.current.clientWidth);
            }
        });
    });

    const onImageLoaded = () => {
        setIsLoaded(true);
    }

    return <Wrapper ref={wrapperRef}>
        {/* {sortedSizes.map((size, i) => {
            var h = height > -1 ? height : Math.round(size / aspectRatio);
            var media = `(min-width: ${size}px)`;
            var srcSet = `${urlBuilder.width(size).height(h).auto('format').url()} ${size}w`;

            return <source key={i} media={media} srcSet={srcSet}/>
        })}
        <source media={`(max-width: ${smallestSize}px)`} srcSet={`${urlBuilder.width(smallestSize).height(height > -1 ? height : smallestHeight).auto('format').url()} ${smallestSize}w`}/> */}    
        <Placeholder 
            initial={isLoaded ? "loaded" : "loading"}
            animate={isLoaded ? "loaded" : "loading"}
            variants={fadeOutState}
        ></Placeholder>
        {isInitialized &&
            <Image 
                src={urlBuilder.width(width).height(height).auto('format').url()} 
                alt={alt}
                onLoad={onImageLoaded}>
            </Image>
        }
    </Wrapper>;
}

ResponsiveImage.propTypes = {
    urlBuilder: PropTypes.object.isRequired,
    sizes: PropTypes.arrayOf(PropTypes.number),
    primarySize: PropTypes.number,
    preview: PropTypes.bool,
    alt: PropTypes.string,
    aspectRatio: PropTypes.number,
    types: PropTypes.arrayOf(PropTypes.string),
};
  
ResponsiveImage.defaultProps = {
    sizes: [480, 640, 720, 750, 1080, 1366, 1440, 1920, 2304, 2560, 2732, 2880, 2960, 5120], // Common sizes from http://screensiz.es/
    primarySize: 1080,
    alt: '',
    aspectRatio: 16/9,
    types: ["image/webp", "image/jpg", "image/png"]
};

export default ResponsiveImage;