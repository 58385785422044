import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { format, parseISO, addHours } from 'date-fns';
import { nb } from 'date-fns/locale';
import Agenda from '../calendar/Agenda';

const CalendarWrapper = styled.time`
`

const CalendarHeader = styled.header`
  display: flex;
  align-items: baseline;
  padding-left: ${({ theme }) => theme.radius};
  margin-bottom: ${({ theme }) => theme.margins.small};

  h2 {
    margin: 0;
    padding: 0;
  }

  a {
    font-size: ${({ theme }) => theme.fontSizes.small};
    margin-left: ${({ theme }) => theme.margins.medium};
  }
`

const Calendar = ({ events }) => {
  return <CalendarWrapper dateTime={format(parseISO(events[0].date), "yyyy-'W'ww", { locale: nb })}>
    <CalendarHeader>
      <h2>Hva skjer</h2>
      <Link href="/calendar">
        <a>Se agenda &raquo;</a>
      </Link>
    </CalendarHeader>
    <Agenda days={events}/>
  </CalendarWrapper>
}

export default Calendar;