import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import SimpleBlockContent from '../components/common/SimpleBlockContent';
import Calendar from '../components/frontpage/Calendar';
import ArticleGrid from '../components/frontpage/ArticleGrid';
import SanityBackendClient from '../lib/SanityBackendClient';
import { NextSeo } from 'next-seo';

const Main = styled.main`
  width: 100%;
  padding-top: ${({ theme }) => theme.radius};

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium.maxWidth}) {
    width: calc(70% - ${({ theme }) => theme.radius});
    margin-right: ${({ theme }) => theme.breakpoints.medium.margin};
  }
`

const NewsHeader = styled.header`
  display: flex;
  align-items: baseline;

  h1 {
    margin: 0 0 ${({ theme }) => theme.margins.small} 0;
    text-transform: uppercase;
    font-size: ${({ theme }) => theme.fontSizes.large};
    font-weight: 300;
    padding: 0 0 0 ${({ theme }) => theme.radius};
  }

  a {
    font-size: ${({ theme }) => theme.fontSizes.small};
    margin-left: ${({ theme }) => theme.margins.medium};
  }
`

const Aside = styled.aside`
  width: 100%;
  padding-top: ${({ theme }) => theme.margins.large};

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium.maxWidth}) {
    width: calc(30%);
    margin-right: ${({ theme }) => theme.breakpoints.medium.margin};
    padding-top: ${({ theme }) => theme.radius};
  }
  
  h2 {
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    font-size: ${({ theme }) => theme.fontSizes.large};
    font-weight: 300;
  }
`

const Notices = styled.div`
  margin-top: ${({ theme }) => theme.margins.large};

  h2 {
    margin: 0 0 ${({ theme }) => theme.margins.small} 0;
    padding-left: ${({ theme }) => theme.radius};
  }

  .notice {
    padding: ${({ theme }) => theme.margins.medium} ${({ theme }) => theme.margins.large};
    background: ${({ theme }) => theme.colors.background};
    border-radius: ${({ theme }) => theme.radius};
    font-size: ${({ theme }) => theme.fontSizes.small};

    p:first-child {
      margin-top: 0;
      padding-top: 0;
    }

    p:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
`

const Index = ({ articles, events, frontpage, preview }) => {
  return <>
    <NextSeo
      title="BCC Bergen"
    />
    <Main>
      <NewsHeader>
        <h1>Siste nytt</h1>
        <Link href="/articles">
          <a>Flere artikler &raquo;</a>
        </Link>
      </NewsHeader>
      <ArticleGrid articles={articles}></ArticleGrid>
    </Main>
    <Aside>
      <Calendar events={events}></Calendar>
      {frontpage?.notices && <Notices>
        <h2>Viktig info</h2>
        <div className="notice">
          <SimpleBlockContent blocks={frontpage.notices}></SimpleBlockContent>
        </div>
      </Notices>}
    </Aside>
  </>
}

export const getStaticProps = async ({ params, previewData, preview = false }) => {
  const sanityBackendClient = new SanityBackendClient();
  const siteInfo = await sanityBackendClient.getSiteInfo(preview);
  const articles = await sanityBackendClient.getAllArticlesForHome(preview);
  const events = await sanityBackendClient.getCalendarForHome(preview);
  const frontpage = await sanityBackendClient.getHomePageData(preview);
  const menu = await sanityBackendClient.getMenu(preview);

  return {
    props: { menu, articles, events, siteInfo, preview, frontpage },
    revalidate: 1
  }
}

export default Index;